import React, {useState} from "react";
import Button from 'react-bootstrap/Button';

import { useForm } from "../../hooks/FormContext";

function RefreshButton() {    
    
  const [allButtonText, setAllButtonText] =  useState("Ninguno");  
  const [allButtonVariant, setAllButtonVariant] =  useState("outline-secondary");
  const {checkLanguages, unCheckLanguages} = useForm();
    
  const handleAllButtonChange = () => {
    if(allButtonVariant=="outline-secondary"){
        setAllButtonVariant("outline-success");
        setAllButtonText("Todos");  
        unCheckLanguages();
    }
    else {
        setAllButtonVariant("outline-secondary");
        setAllButtonText("Ninguno");
        checkLanguages();
    }
  } 

    return (        
        <Button variant={allButtonVariant}  onClick={handleAllButtonChange}>{allButtonText}</Button>        
    )
}

export default RefreshButton;