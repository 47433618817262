import React from "react";
import Button from 'react-bootstrap/Button';

import { useForm } from "../../hooks/FormContext";
import { checkTranslations } from "../../services/api";

function RefreshButton({ showGenerate }) {
    const { getFormData, updateTranslationContent } = useForm();

    /**
     * "submit" form data to check if the translations are ready/pending/not created
     */
    const clickRefresh = () => {
        checkTranslations(getFormData(true))
            .then(res => {
                console.log("Response ", res.data);
                updateTranslationContent(res.data);
                //Show generate button
                showGenerate(true);
                updateTranslationContent(res.data);
            })
            .catch(error => {
                console.log("Error in submit ", error);
            })
    }

    return (
        <Button variant="outline-primary" onClick={clickRefresh} >Actualizar</Button>
    )
}

export default RefreshButton;