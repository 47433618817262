import React from 'react';


export const customSelectStyles = {
    container: provided => ({
      ...provided,
      width: 200
    })
};

export const labelStyle = {

}

export const mainDivStyle = {
    margin: '0px 5px'
}