import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});


export const checkTranslations = (data) => {
  return api.post("/translate/show", data);
}

export const generateTranslations = (data)  =>{
  return api.post("/translate/createEntities", data);
}

export const getProjectNames = () => {
  return api.get("/translate/projectsName");
}

export const getDocumentNames = (projectId) => {
  return api.post("/translate/documentsName", projectId);
}