import React, { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

import {AgGridReact} from 'ag-grid-react';
import SearchBox from '../search-box/search-box';
import {getTableData, validateButton} from './tableFunctions';
import TranslateEditor from './TranslateEditor';
import SelectorFilter from '../SelectorFilter/SelectorFilter';
import DropLanguage from "../DropLanguage";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AiOutlineClockCircle, FiEdit, FaRegCheckCircle, FiUserCheck} from "react-icons/all";
import '../../styles/agTable.css'
import Select from "react-select";


const AgTable = (props) => {
    let { lang } = props;
    const optionsStatus = [
        { value: 'review', label: 'Review' },
        { value: 'ready', label: 'Ready' },
        { value: '', label: 'All' }
    ]
    const [gridApi,setGridApi]= useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([
        {_id:'60af9755cf36d40009abee8645', isArticle:0, req_text: "Frase de ejemplo", text: "Example sentence", status: "review", project:"Test", document:"AgGrid", createdAt:"14/05/94"},
    ]);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [loading, setLoading] = useState(true);
    const [projectOptions, setProjectOptions] = useState([]);
    const [documentOptions, setDocumentOptions] = useState([]);
    const statusRef=useRef("review");
    const projectRef=useRef("");
    const documentRef=useRef("");
    const defaultColDef = {
    resizable: true,
    filter: true,
    sortable: true,
    flex: 1,
    editable:false,
  };
   const columnDefs = [     
     {headerName: 'Actions', cellRenderer: "actionsRenderer"},
    {
      headerName: 'Original',
      field: 'req_text',
      flex: 4,
      /*Selection*/
      /*headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,*/
     
    },
    {headerName: 'Translation', field: 'text',flex: 4},
    {headerName: 'Status', field: 'status', cellRenderer: "statusRenderer"},
    {headerName: 'Project', field: 'project'},
    {headerName: 'Document', field: 'document'},
    {headerName: 'Creation', field: 'createdAt'},
  //  {headerName: '',field: 'img',cellRenderer: "imageRenderer",editable:false,},              
  ];

  useEffect(() =>{
    setLoading(true);
    console.log("Lang is different");
    if(lang)
    {
      getTableData(lang,0,30,null,"desc")
        .then(tableData => {
          updateData(tableData.data);
        })
        .catch(err => console.log("Error updating table data ",err));
        //Check if inicialized. Used when lang selector is changed
        if(gridApi){
          gridApi.refreshCells();
          gridApi.onFilterChanged();
        }
    }
    else {
      setLoading(false);
    }     
  }, [lang]);

  const handleValidateButton = param => e => {   
    const row = param.data;
    validateButton(row, lang, param.api);  
  };

  const handleDetailClick =  param => e => {       
    console.log("Click Detail");
    const row = param.data;
    let modalData = {
      translationId: row._id,
      lang:lang,
      isArticle: row.isArticle,
      api: param.api
    }
    props.setModalData(modalData);
    console.log("Show modal");
    props.handleShow();
  };
  function actionsRenderer(props) {
    if(props.data.status==="review")
    {
      return (
        <div className="actionsContainer">
            <a onClick={handleValidateButton(props)} href="#" className="text-success validateIcon iconContainer">
                <FaRegCheckCircle/>
            </a>
            <a onClick={handleDetailClick(props)} href="#" className="editIcon iconContainer">
              <FiEdit/>
            </a>
        </div>
     );
    }
    else {
      return (
          <div className="actionsContainer">
              <a onClick={handleDetailClick(props)} href="#" className="editIcon iconContainer">
                <FiEdit/>
              </a>
          </div>
     );
    }

    }

    function statusRenderer(props) {
    if(props.data.status==="review")
    {
      return (
          <div className="descDetailMenu statusReview"><AiOutlineClockCircle/> {props.data.status}</div>
     );
    }
    else {
      return (
          <div className="descDetailMenu statusReady"><FiUserCheck/> {props.data.status}</div>
     );
    }

    }

  const updateData = (data) => {
    console.log("Update table data ",data);
    setRowData(data);
    setLoading(false);
  };
  
  function sortChanged(params){
    console.log("sort changed", params);
  }

   function  onGridReady(params) {
     console.log("Grid ready");
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    if(quickFilterText!=="")
      params.api.setQuickFilter(quickFilterText);  
  }

  /**
   * Triggers after filters have been activated
   * @param {*} params 
   */
  function onModelUpdated(params) {
    let projects=[]
    let documents=[]; 
    params.api.forEachNodeAfterFilter(node => {
      if (node.data.project) 
        if(!projects.includes(node.data.project))   
          projects.push(node.data.project);
      
      if (node.data.document)
        if(!documents.includes(node.data.document))
          documents.push(node.data.document);      
     })
     setProjectOptions(projects);
     setDocumentOptions(documents);
  }

  function onChangeFilter(text){
    setQuickFilterText(text);
    gridApi.setQuickFilter(text);
  }

  function onChangeSelect(e){
    statusRef.current =e.value;
    gridApi.onFilterChanged();
  }

  const isExternalFilterPresent = () => {    
    return statusRef.current !== "" || projectRef.current !== "" ||  document.current !== "";
  }
  
  /**
   * Manage external filters logic
   * @param {*} node 
   * @returns True or false if node.data matches with filters
   */

  const doesExternalFilterPass = (node) => {
    //console.log("Node: ", node.data.status , " query ", statusRef.current);
    let returnBool = false; 

    if(statusRef.current ==="") returnBool =  true;
    else {
      returnBool  = node.data.status == statusRef.current 
      if(!returnBool) return returnBool;
    }

    //Project filter is contained in row data
    if (node.data.project){
      let projectLower = node.data.project.toLowerCase();
      if(projectLower.includes(projectRef.current.toLowerCase())){
        returnBool = true;
      }
      else return false;
    }else{
      //console.log("Node undefined ", node);
      return false;
    }    

    //Document filter is contained in row data
    if(node.data.document){
      if(node.data.document.toLowerCase().includes(documentRef.current.toLowerCase())){
        returnBool = true;
      }
      else return  false;
    }    

    return returnBool;
  }


  function saveParams(id,params){
    /*Llamada a la API*/
  } 

  
  const rowValueChanged = (grid) =>{
        var params= grid.data
        saveParams(params["id"],params);
  }

  const showSkeleton = () =>(
      <>
          <div style={{display:"flex", padding:"20px"}}>

              <Skeleton width={100} height={40} style={{margin:"5px"}}/>
              <Skeleton width={100} height={40} style={{margin:"5px"}}/>
              <Skeleton width={100} height={40} style={{margin:"5px"}}/>

              <div className="spacer"></div>

              <Skeleton width={100} height={40} style={{margin:"5px"}}/>

          </div>
          <div style={{ padding:"5px 20px"}}>
              <Skeleton height={40} style={{marginBottom:"10px"}}/>
              <Skeleton count={20} />
          </div>
      </>

  );
    const gridOptions = {
      animateRows: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      pagination: true,
      paginationPageSize: 15,
      defaultColDef:defaultColDef,
      columnDefs:columnDefs,
      multiSortKey: "ctrl",
      onGridReady: onGridReady,
      onSortChanged: sortChanged,
      onModelUpdated: onModelUpdated,
      editType:'fullRow',
      onCellValueChanged:{rowValueChanged},
      cacheQuickFilter: true,
      getRowNodeId: (data) => {
                return data._id;
              },

      frameworkComponents: {
         actionsRenderer: actionsRenderer,
         TranslateEditor:TranslateEditor,
          statusRenderer: statusRenderer
        },
      isExternalFilterPresent:isExternalFilterPresent,
      doesExternalFilterPass:doesExternalFilterPass

    }

    return (
      <React.Fragment>
        {loading 
          ? (showSkeleton())
          : <React.Fragment>
              {lang
              //If lang render the table
                ?
                  <React.Fragment>
                    <div style={{display:"flex", padding:"20px"}}>
                        <div style={{margin: "0px 5px", display: "flex", alignItems: "flex-end"}}>
                            <SearchBox onChange={onChangeFilter}/>
                        </div>
                        <div style={{margin: '0px 5px'}}>
                            <label className="titleFilter">Status</label>
                            <Select className='status-select-container' isSearchable={false} defaultValue={optionsStatus[0]} options={optionsStatus} onChange={onChangeSelect} placeholder="Status">
                            </Select>
                        </div>
                        <SelectorFilter options={projectOptions} gridApi={gridApi} ref={projectRef} placeholder="Project"/>
                        <SelectorFilter options={documentOptions} gridApi={gridApi} ref={documentRef} placeholder="Document"/>     
                        <div className="spacer"></div>
                        <DropLanguage lang={lang} changeLang={props.changeLang}></DropLanguage>
                    </div>
                    <div className="ag-theme-alpine" style={{height: 800, width: "100%"}}>
                      <AgGridReact
                        rowData={rowData}
                        gridOptions={gridOptions} >
                      </AgGridReact>
                    </div>         
                  </React.Fragment>
                : //if not make the user pick a language
                  <React.Fragment>
                    <p>Select language</p>
                    <DropLanguage lang={lang} changeLang={props.changeLang}></DropLanguage>
                  </React.Fragment>
              }
            </React.Fragment>
        }
      </React.Fragment>
    );

   
};

export default AgTable;