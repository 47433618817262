import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {Modal, Button} from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';

import {OriginalArea} from '../components/Details/OriginalArea';
import {TranslationArea} from '../components/Details/TranslationArea';
import {TranslationAreaArticle} from '../components/Details/TranslationAreaArticle';
import {TranslationInfo} from '../components/Details/TranslationInfo';


const URL_EDIT = process.env.REACT_APP_API_URL+"/grid/edit";
const URL_UPDATE = process.env.REACT_APP_API_URL+"/grid/update";


const emptyTranslation = {
    req_text: "error",
    text: "error",
}

function  Detail(props) {  
    const [translation, setTranslation] = useState({});  
    const [loading, setLoading] = useState(true);
    const {show, handleClose, modalData} = props;
    console.log("ModalData: ", modalData);
    const {translationId, lang, isArticle, api} = modalData;
    const translationRef =useRef("");
    const updateTranslation = (value) =>{
            //console.log("Current value ", translationRef.current);
            translationRef.current =value;
        }

    useEffect(() =>{
        const url = URL_EDIT + '/' + lang + '/' + translationId+ '/' + isArticle;     
        console.log("URL is " + url);
        setLoading(true);
        axios.get(url)
            .then((response) => { 
                console.log(JSON.stringify(response.data));
                if(response.data.translation)
                    setTranslation(response.data.translation);
                else    
                    setTranslation(emptyTranslation);
                setLoading(false);
            })            
            .catch(error => console.log("Error in GET ",error));    
    }, [translationId,lang, isArticle]);    

     
  /*  const translationId = "608001a6ea22b100f6f8de9b";
    const lang = "it";
    const isArticle = "0";*/
        
        const onSubmitClick = () =>{        
            const data = {
                lang,
                text: translationRef.current,
                isArticle: isArticle,
                _id: translationId
            }
            debugger;
            axios.patch(URL_UPDATE, data)
                .then(response => {
                    console.log("Response" ,response);     
                    api.applyTransaction({ update: [data] });    
                    handleClose();          
                })            
                .catch(error => console.log("Error in GET ",error));          
        }

        const renderTranslationArea = () => {
            let boolArticle = (isArticle =='1');
            if(boolArticle){
                console.log("IS ARTICLE ",boolArticle);
                return <TranslationAreaArticle title="Translation"  content={translation.text} updateTranslation={updateTranslation} />
            }else {
                console.log("IS NOT ARTICLE", boolArticle);
                return <TranslationArea title="Translation"  content={translation.text} updateTranslation={updateTranslation} />
            }
        }

        const showSkeleton = () =>(
            <>
                <div style={{display:"flex", padding:"20px"}}>
      
                    <Skeleton width={100} height={40} style={{margin:"5px"}}/>
                    <Skeleton width={100} height={40} style={{margin:"5px"}}/>
                    <Skeleton width={100} height={40} style={{margin:"5px"}}/>
      
                    <div className="spacer"></div>
      
                    <Skeleton width={100} height={40} style={{margin:"5px"}}/>
      
                </div>
                <div style={{ padding:"5px 20px"}}>
                    <Skeleton height={40} style={{marginBottom:"10px"}}/>
                    <Skeleton count={20} />
                </div>
            </>
      
        );
        
        return (
            <React.Fragment>
               { loading
                    ? showSkeleton()
                    : <React.Fragment>
                        <Modal show={show} onHide={handleClose}  backdrop="static" size="xl">
                            <div style={{width: "95%", margin: "20px auto"}}>             
                                <Modal.Header closeButton>
                                    <TranslationInfo {...translation} />
                                </Modal.Header>
                                
                                <Modal.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <OriginalArea title="Original"  content={translation.req_text} />
                                    </div>
                                    <div className="col-md-6">
                                        {renderTranslationArea()}   
                                    </div>
                                </div>

                                </Modal.Body>
                                <Modal.Footer>
                                <div id="submitDiv">
                                    <div style={{float: "right", marginTop: "20px"}} >
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={onSubmitClick}>
                                            Save Changes
                                        </Button>                       
                                    </div>
                                </div>     
                                </Modal.Footer>          
                            </div>
                        </Modal>
                    </React.Fragment>
                }                
            </React.Fragment>    
        );
    
  }
  
  export default Detail;
  