import React, { useState } from "react";
import Button from 'react-bootstrap/Button';

import GeneratePopUp from "./GeneratePopUp/GeneratePopUp";
import { useForm } from "../../hooks/FormContext";

function GenerateButton({updateSubTitle}) {
    const { resetForm } = useForm();
    const [showPopUp, setShowPopUp] = useState(0);

    const generateSuccess = () => {
        setShowPopUp(false);
        updateSubTitle("Traducciones creadas correctamente");
        resetForm();
    }
    
    const generateFailure = () => {
        setShowPopUp(false);
        updateSubTitle("Error generando las traducciones");
    }

    return (
        <>
            <Button variant="outline-primary" onClick={() => setShowPopUp(true)}>Generar</Button>
            <GeneratePopUp
                show={showPopUp}
                onHide={() => setShowPopUp(false)}
                generateFailure={generateFailure}
                generateSuccess={generateSuccess}
            />
        </>
    )
}

export default GenerateButton;