import React from "react";
import {  Redirect } from "react-router-dom";

import {useUser} from "../hooks/UserContext";

const API_URL = process.env.REACT_APP_API_URL+"/users/auth/";
const Login = ()  => {
   
    const {isAuth,   getLanguages, getRole, checkRole, isTranslator} = useUser();    
    
    console.log("Check login "+isAuth);
    if(!isAuth){
        console.log("No AUTH, redirect to: "+API_URL);
        window.location.href= API_URL ;
        return (<p>Login</p>);
    }            
    else{
        checkRole();
        //AxiosHeaders();
        let languages = getLanguages();
        console.log("We got languages ",languages);
        if(typeof languages ){       
            let role = getRole();
            if(!role)  return(<Redirect to="/profile"></Redirect>);  
            console.log("IsTranslator "+isTranslator);
            console.log("Push to /"+languages[0] + " with role: "+ role + " from login");   
            let route = "/"+languages[0]; 
            return(<Redirect to={route}></Redirect>);            
        }
        else{
            console.log("No languages redirect to profile ");
            return(<Redirect to="/profile"></Redirect>);  
        }
    }    
    
       /*
    return (
        <React.Fragment>
            <h1>Login page</h1>
            { isAuth 
            
               ? <input type="button" onClick={logOut} value="Log out"/>

               :          
                <div>
                    Login
                </div>
            }           
        </React.Fragment>
    );*/
            
  
}

export default Login;