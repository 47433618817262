import React from "react";
import { Link } from "react-router-dom";
import {  Navbar, Nav } from "react-bootstrap";

import logo from "../assets/static/hermes_logo_white.svg"
import "bootstrap/dist/css/bootstrap.min.css";

function Menu(props) {
    const color_nav = {
        backgroundColor: "#194163"
    }
  return (
    <React.Fragment>
      <Navbar variant="dark" expand="lg" style={color_nav}>
      <Navbar.Brand as={Link} to="/">
        <img style={{height: "30px"}} alt="logo" src={logo}/>
        
     </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Translations
            </Nav.Link>
            <Nav.Link as={Link} to="/translate">
              Translate
            </Nav.Link>
            <Nav.Link as={Link} to="/document">
              Documents
            </Nav.Link>
            <Nav.Link as={Link} to="/login">
              Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {props.children}
    </React.Fragment>
  );
}

export default Menu;
