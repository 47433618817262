import React from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "../../hooks/FormContext";


export const IsArticleCheck = () => {
    const {getFormData, updateFormData} =  useForm()
    const formData = getFormData();
    const idText = "isArticle";
    const text = "Es un artículo";
    const onClick = () => {
        updateFormData(
            {
                isArticle: !formData["isArticle"],
                is_repeatable: formData["isArticle"]
            });
    }

    return (        
        <Form.Check
        inline
        type="switch"
        label={text}
        name={idText}
        id={idText}
        checked={formData["isArticle"]}
        onChange={onClick}
      />
    );
}