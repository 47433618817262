import React, {useState, useEffect} from "react";

import AgTable from "../components/AgTable/AgTable";
import { useHistory, useParams } from 'react-router-dom';
import {useUser} from '../hooks/UserContext';
import Detail  from './Detail';


function Grid () {  
  const { getLanguages} = useUser();
  let { lang } = useParams();
  let history = useHistory();
  const style = {
    width: '95%',
    margin: '0% 2% 0% 2%',
    fontSize: '0.9em',
  };
  const [stateLang, setStateLang] =useState(lang);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    translationId: "",
    lang:lang,
    isArticle: 0
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateModalData = (data) => setModalData(data); 

  
  useEffect(() =>{
    if(!lang){
      let languages = getLanguages();
      setStateLang(languages[0]);
      history.push("/"+languages[0]);
    }
  }, [lang]);

    return (
      <div style={style}>                      
        <AgTable lang ={stateLang} changeLang={setStateLang} setModalData={updateModalData} handleShow={handleShow}></AgTable>   
        {show && 
          <Detail handleClose={handleClose} show={show} modalData={modalData}></Detail>
        }
        
      </div>
    );
  
}
export default Grid;
