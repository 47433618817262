import React from "react";
import Form from 'react-bootstrap/Form'

import { useForm } from "../../hooks/FormContext";

const OriginalArea = () => {   
    const {getFormData, updateFormData} = useForm();
    const formData = getFormData();    

    const handleOriginalChange = (e) =>   updateFormData({originalText:e.target.value});        
    return (
        <Form.Control
            as="textarea"
            placeholder="Introduzca el texto a traducir"
            style={{ height: '20%' }}
            onChange={handleOriginalChange}
            name="originalText"
            value={formData["originalText"]}
        />
    );
};



export default OriginalArea;
