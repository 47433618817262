import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap/";
import CreatableSelect from 'react-select/creatable';
import Skeleton from 'react-loading-skeleton';

import { useForm } from "../../../hooks/FormContext";


import { getProjectNames, getDocumentNames, generateTranslations } from "../../../services/api"

import 'react-calendar/dist/Calendar.css';


function GeneratePopUp(props) {
    const { generateSuccess, generateFailure } = props;
    const { getFormData, getLangs } = useForm();
    const [disableGenerate, setDisableGenerate] = useState(true);
    const [showDocumentsSelect, setShowDocumentsSelect] = useState(false);
    const [showDocumentSkeleton, setShowDocumentSkeleton] = useState(false);
    const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(false);
    const [project, setProject] = useState();
    const [document, setDocument] = useState();
    const [projectOptions, setProjectOptions] = useState([]);
    const [documentOptions, setDocumentOptions] = useState([]);
  
    const prepareOptionsArray = (optionsArray) => {
        const options = [];
        optionsArray.map((project) => {
            options.push({
                value: project["_id"],
                label: project["name"]
            })
        });
        return options;
    }

    useEffect(() => {
        getProjectNames()
            .then((response) => {
                setProjectOptions(prepareOptionsArray(response.data));
            })
            .catch((error) => {
                console.log("Projects error ", error);
            });
    }, [])

    const changeProjectSelect = ({ label, value }, accion) => {
        if (label) {
            setProject(label);
            //If label is equals to value the user is creating a new project
            if (label === value) {
                setShowDocumentsSelect(true);
                setDocumentOptions([]);
            }
            else {
                setShowDocumentSkeleton(true);
                setShowDocumentsSelect(false);
                getDocumentNames({ project: label })
                    .then((documents) => {
                        console.log("Documents ", documents);
                        setDocumentOptions(prepareOptionsArray(documents.data));
                    })
                    .catch((error) => {
                        console.log("Error loading documents ", error);
                        setDocumentOptions({
                            value: 0,
                            label: "Error cargando documentos"
                        })
                    })
                    .finally(() => {
                        setShowDocumentSkeleton(false);
                        setShowDocumentsSelect(true);
                    });
            }

        }
    }

    const changeDocumentSelect = (selected, accion) => {
        if (selected) {
            setDocument(selected.label);
            setDisableGenerate(false);
        }
    }

    const createTranslations = () => {
        debugger;
        const formData = getFormData(true);
        const langs = getLangs();
        const target_langs = []
        for (const lang in langs) {
            if (formData[lang]) {
                target_langs.push(lang);
            }
        }
        const popUpData = { project, document, ...formData, target_langs, text: formData["originalText"] };
        setShowLoadingSkeleton(true);
        generateTranslations(popUpData)
            .then((result) => {
                debugger;

                generateSuccess();
            })
            .catch((error) => {
                console.log("Error generating translations ", error);
                generateFailure();
            })
            .finally(() => {
                setShowLoadingSkeleton(false);
            });
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Registro de traducciones
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreatableSelect
                    isClearable
                    placeholder="Proyecto"
                    isSearchable={true}
                    options={projectOptions}
                    onChange={changeProjectSelect}
                />
                {showDocumentSkeleton &&
                    <Skeleton circle={false} height={50} width={550} />}
                {showDocumentsSelect &&
                    <CreatableSelect
                        isClearable
                        placeholder="Documento"
                        isSearchable={true}
                        options={documentOptions}
                        onChange={changeDocumentSelect}
                    />}
                {showLoadingSkeleton &&
                    <Skeleton circle={true} height={50} width={50} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
                <Button
                    variant="primary"
                    disabled={disableGenerate}
                    onClick={createTranslations}
                >
                    Registrar
                </Button>
            </Modal.Footer>
        </Modal>

    );
}


export default GeneratePopUp;