import axios from "axios";


const AxiosHeaders= ()  => {
    //const {logOut} = useUser();

    let token = localStorage.getItem("token");
   // console.log("Store token in header ",token);
    if (token) {
        axios.defaults.headers.common['x-access-token'] = token;
    } else {
        axios.defaults.headers.common['x-access-token'] = null;        
    }
    axios.interceptors.response.use(response => {
        return response;
     }, error => {
         console.log("Axios error:",error);
         if(typeof error.response !== 'undefined'){
                if (error.response.status === 401) {                      
                  //  logOut();
                    //LogIn
                    const API_URL = process.env.REACT_APP_API_URL+"/users/auth/";
                    window.location.href= API_URL ;
            }
         }
       
       return error;
     });
}

export default AxiosHeaders;