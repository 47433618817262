import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';


import ProtectedRoutes from './hooks/ProtectedRoutes';
import Profile from './pages/Profile';
import Login from './pages/Login';
import LoginSuccess from './pages/LoginSuccess';

import Menu from './components/Menu';

import './App.css';

require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` })

function App() {  
    
  return (
  <BrowserRouter>   
    <Menu>      
      <Switch>              
        <Route path="/profile" component={Profile}></Route>
        <Route path="/login/success/:data" component={LoginSuccess}></Route>  
        <Route path="/login" component={Login}></Route>
        <ProtectedRoutes />            
      </Switch>
    </Menu>
  </BrowserRouter>
  );
}

export default App;
