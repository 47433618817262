import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import Flag from "react-world-flags";

function TranslationLang(props) {    
    const {text, lang } = props;
    const [copyVariant, setCopyVariant] =  useState("warning");
    
    /**
     * change back-ground color whether the translation is ready (green) or not (orange)
     */
    const checkCopyButonColor = () => {
        let match = text.match(/<\/etiqueta>/g);
        if (!match) setCopyVariant("success");
        else setCopyVariant("warning");
    };

    /**
     * Prepare country code if language is en it returns gb for the country
     * @returns country code for flag component
     */
    const getFlagCode = () => {
        if(lang =="en")  return "gb";        
        else return lang;
    }

    /**
     * Clean pre and etiqueta tags used for colors before adding the text into the clipboard
     */
    const clickCopyButton = () => {
        let textToCopy = text.replace(/(<pre>)|(<\/pre>)|(<\/etiqueta>)/ig,"");          
        textToCopy = textToCopy.replace(/<etiqueta.*'>/ig,"");
        navigator.clipboard.writeText(textToCopy);
    }

    useEffect(() =>{
        checkCopyButonColor();
    }, [text])

    return (    
        <>    
            <div className="translateDiv"  dangerouslySetInnerHTML={{__html: text}}></div>  
            <Button 
                variant={copyVariant} 
                onClick={clickCopyButton}>
                    <FileCopyRoundedIcon/>
            </Button>
            <Flag 
                code={getFlagCode()}  
                height="32" width="32"
                fallback={ <span>Unknown code for lang {lang}</span> }
            />
        </>
    )
}

export default TranslationLang;