import React from "react";
import {  Switch, Route, Redirect } from 'react-router-dom';

import Translate from '../pages/Translate';
import Grid from '../pages/Grid';
import Detail from '../pages/Detail';
import Test from '../pages/Test';


import {useUser} from './UserContext';

const ProtectedRoutes = ({...props}) => {
    const { isTranslator, isProducer, isAuth} = useUser();
    console.log("Console from Protected Route "+ isTranslator+ "  " + isProducer);
    if(!isAuth) {return (<Redirect to="/login" />);}
    
    if(isTranslator){
        console.log("Routing to translator");
        return (
        <Switch>
            <Route path="/translate" component={Translate} ></Route>        
            <Route path="/detail/:lang/:translationId/:isArticle" component={Detail} ></Route>
            <Route path="/test" component={Test}></Route>                                   
            <Route path="/:lang" component={Grid}></Route>
            <Route path="/" component={Grid}></Route>
        </Switch>
        );
    }
    else if(isProducer){
        console.log("Routing to producer");
        return(<Switch>
            <Route path="/translate" component={Translate} ></Route>             
        </Switch>);
    }
    else{
        console.log("Redirect to login");
        return (<Redirect to="/login" />);
    }
}

export default ProtectedRoutes;