import React, {  forwardRef, useImperativeHandle, useRef } from "react";

const  TranslateEditor = forwardRef((props, ref) => {
    const inputRef = useRef();

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
              console.log("Value: ",inputRef.current.value);
              console.log("RowNode: ", props.node.data._id);
                return inputRef.current.value;
            },
            isPopup() {
                return true;
            },

        };
    });
    return <input style={{ width:"500px", height:"50px"}}  type="text" ref={ inputRef } defaultValue={ props.value }/>;
  })

  export default TranslateEditor;