import React from "react";
import { useHistory, useParams } from 'react-router-dom';

import {useUser} from "../hooks/UserContext";

const LoginSuccess = ()  => {

    const {logIn, logOut, getLanguages, getRole} = useUser();

    let {data} = useParams();
    let history = useHistory();
    try {
        console.log("TOKEN",data);
        logIn(data);
        console.log("Token: ", data);
        let languages = getLanguages();
        console.log("Languages from token: " + languages);
        if(!languages){            
            console.log("Push to profile, no languages");
            history.push("/profile");
        }                
        else{
            console.log("There are languages!");
            let role = getRole();
            console.log("push to role view  "+ role);
            if(role === "PRODUCER"){
                history.push("/translate")
            }
            else{
                history.push("/"+languages[0]);
            }
        }
                
    } catch (error) {
        console.log("Error logging in ",error );
        logOut();
    }
    
    return (
        <React.Fragment>
            <h1>Login success</h1>
            Token: {data}    
        </React.Fragment>
    );
}

export default LoginSuccess;