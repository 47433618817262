import React from "react";
import ReactHtmlParser from 'react-html-parser';
import "../../styles/detail.css";

import './DivArea.css';

export const OriginalArea = (props) =>{   
    return(
        <div>
            <h2 className="title_box" >{props.title}</h2>
            
                <div className="scrollbars column ">
                    <div >
                         { ReactHtmlParser(props.content)} 
                    </div>                    
                </div>
            
        </div>
    );
};