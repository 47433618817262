import React from "react";
import { useHistory } from 'react-router-dom';
import Select, { components } from "react-select";
import Flag from 'react-world-flags'
import '../styles/dropLanguaje.css'

function DropLanguage({lang, changeLang}){
    const history = useHistory();  
    /*const style = {
        position: 'absolute',
        right: '0',
      };*/

    const onChange = (e) => {
        console.log("Change language to ",e.value);
        //Change URL 
        history.push("/"+ e.value);
        //Change lang data in table and drop down
        changeLang(e.value);
    }
    const optionsLang = [
        { value: 'en', label: 'EN', icon:'GB' },
        { value: 'pt', label: 'PT', icon:'PT' },
        { value: 'it', label: 'IT', icon:'IT' },
        { value: 'fr', label: 'FR', icon:'FR' },
        { value: 'de', label: 'DE', icon:'DE' }
    ]

    //Personalización del renderizado con la bandera de las opciones del selector
    const { Option } = components
    const IconOption = (props) => (
        <Option {...props}>
            <Flag code={props.data.icon} width="20"/> <span style={{marginLeft:5}}>{props.data.label}</span>
        </Option>

    );

    //Personalización del renderizado del elemento seleccionado
    const CustomSelectValue = props => (
        <div>
            <Flag code={props.data.icon} width="30"/>
        </div>
    )

    return (

        <div style={{margin: '0px 5px'}}>
            <label className="titleFilter">Lang</label>
            <Select components={{ Option: IconOption, SingleValue: CustomSelectValue }} className="languageSelect" isSearchable={false} defaultValue={optionsLang.filter(e=> e.value == lang)} options={optionsLang} onChange={onChange} placeholder="Lang">
            </Select>
        </div>
    )
}

export default DropLanguage;