import Moment from 'moment';
import axios from "axios";

const MAX_TEXT_LENGTH = 100;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL+"/grid"
});


const prepareData = (translations) =>{
    let dataPrepared = [];
    Moment.locale('en-gb');   
    let createdAtMoment  = "";
    let today = Moment(new Date());
    translations.forEach( row => {
      if (row.text == null) {
        console.log("ROW ",row);
      }
      else{
        let length = row.text.length;
        if(length > MAX_TEXT_LENGTH){
          let cutText = row.text.substr(0,MAX_TEXT_LENGTH);          
          row.text = cutText + "...";
          let cutReqText = row.req_text.substr(0,MAX_TEXT_LENGTH);
          row.req_text = cutReqText + "...";
          row.isTooLong = true;
          
        } 
        try {
          //Format creation date and calculate translation's living days
          createdAtMoment = Moment(new Date(row.createdAt));
          row.createdAt = createdAtMoment.format('DD/MM/YY') +" ("+today.diff(createdAtMoment,'days') + " days)";
        } catch (error) {
          console.log("Error formatting date: ",row.createdAt," Error: ",error);
        }        
    }
      dataPrepared.push(row);
    })
    let prepared = {
      data:dataPrepared,
      page:0
    }
    console.log(prepared);
    return prepared;
  };
 
  export const getTableData = (lang, page, pageSize, filters, orderBy) => {
    return new Promise(async (resolve, reject) => {
    let postData={
      lang,
      page,
      pageSize,
      filters,
      orderBy
    }
    console.log("PostData ", postData);
    api.post("/",postData)
        .then(res => {  
          let preparedData = {};
          //let total = res.data.total;
          //console.log("URL ",res.data);
          let {translations} = res.data;
          if(translations)              
            preparedData = prepareData(translations);             
          else console.log("Data from request: ", res.data);

          resolve(preparedData);            
         })
         .catch(error=>{
            console.log("URL ",process.env.REACT_APP_API_URL+"/lang");
            console.log("Error in request loading table data ",error);
            reject(error);
         })
    });        
  }

  export const getFilterDataArray = (data, filter) => {
    let filterArray = [];
    for(let element of data){   
      //Check for duplicates with includes
      if(!filterArray.includes(element[filter]))   
        filterArray.push(element[filter]);
    }
    return filterArray;
  }


  export const validateButton = (row, lang,  tableApi) => {
    return new Promise(async (resolve, reject) => {
      const validateUrl = '/validate/' +  lang + '/' + row._id + '/' + row.isArticle;
      console.log("Link will be " + validateUrl);
      api.get(validateUrl)
        .then(response => {
          //Update table data 
          row.status = "ready";
          console.log("Validamos "+JSON.stringify(row) );          
          
          tableApi.applyTransaction({ update: [row] });           
          resolve();
      })            
      .catch(error => {
        console.log("Error in GET ",error);
      // isLoading.current = false;
        reject(error);
      });
    });
    
  }


