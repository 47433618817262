import React, { useState} from "react";
import { Row, Col, Form } from 'react-bootstrap';

import LanguageSelector from "../components/Translate/LanguageSelector";
import AllChecksButton from "../components/Translate/AllChecksButton";
import RefreshButton from "../components/Translate/RefreshButton";
import GenerateButton from "../components/Translate/GenerateButton";
import OriginalArea from "../components/Translate/OriginalArea";
import TranslationArea from "../components/Translate/TranslationArea/TranslationArea";
import { IsArticleCheck } from "../components/Translate/IsAtricleCheck";

import { FormProvider } from "../hooks/FormContext";

import "bootstrap/dist/css/bootstrap.min.css";

//const  TranslationArea = React.lazy(import( "../components/Translate/TranslationArea/TranslationArea"));


function Translate() {
  const [showGenerate, setShowGenerate] = useState(false);
  const [subTitle, setSubTitle] = useState("");

  return (
    <FormProvider>
      <h2>{subTitle}</h2>
      <Form >
        <Row className="align-items-center">
          <Col xs="auto">
            <LanguageSelector />
          </Col>
          <Col xs="auto">
            <AllChecksButton />
          </Col>
          <Col xs="auto">
            <IsArticleCheck />
          </Col>
          <RefreshButton showGenerate={() => setShowGenerate(true)} />
          {showGenerate &&
            <GenerateButton updateSubTitle={setSubTitle} />}
          <OriginalArea />


        </Row>
      </Form>
      {/* <Suspense fallback={<div>Loading Component...</div>}>  */}
      <TranslationArea />
      {/* </Suspense>  */}
    </FormProvider>
  );
}



export default Translate;
