import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import axios from 'axios';

import {useUser} from "../hooks/UserContext";
const Profile = ()  => {
    const {logIn, checkRole} = useUser();
    const [selectedRole, setRoleSelected] = useState(false);
    const [selectedLanguages, setLanguageSelected] = useState(false);
    const history = useHistory();

     const roleOptions = [
        {value: 'TRANSLATOR', label: 'Translator'},
        {value: 'PRODUCER', label: 'Producer'}
    ];

    const handleRole = (selected) => {
        console.log(selected);
        setRoleSelected(selected.value);
    }

    const languageOptions = [
        { value: 'en', label: 'en' },
        { value: 'it', label: 'it' },
        { value: 'pt', label: 'pt' },
        { value: 'fr', label: 'fr' },
        { value: 'pt', label: 'pt' },
        { value: 'de', label: 'de' },
        { value: 'es', label: 'es' },
      ];

    const handleLanguage = (selected) => {
        setLanguageSelected(selected);
    }

    const handleSubmit = () =>{
        if(selectedRole && selectedLanguages)
        {
            console.log("Select Rol in view: ",selectedRole);
            console.log("Selected languages: ");
            for (const selectedLanguage of selectedLanguages){
                console.log(selectedLanguage.value);
            }
            const data = {
                selectedRole,
                selectedLanguages
            }
            
            axios.post(process.env.REACT_APP_API_URL+"/users/setRole", data)
                .then(res => {
                    console.log("Response " +JSON.stringify(res.data));
                    if(res.status===200)
                    {
                        console.log("Log in after setting role");
                        logIn(res.data.message);
                        console.log("Set rol after log in");
                        checkRole();
                         console.log("Push to /" +selectedLanguages[0].value+ " with role: "+selectedRole);

                        if(selectedRole === "PRODUCER"){
                            history.push("/translate")
                        }
                        else{
                            history.push("/"+selectedLanguages[0].value);
                        }
                        
                        console.log("Redirect");
                    }
                    else{
                        console.log("Error in response ");
                    }
                })
                .catch(err  => {
                    console.log("Error setting role and translations ", err);
                })
        }
        else{
            alert("Please, select role and languages");
        }
    }

    return (
        <React.Fragment>
            <h1> Profile</h1>
            <Select options={roleOptions} onChange={handleRole} placeholder="Select Role"/>
            
            <Select options={languageOptions} onChange={handleLanguage} placeholder="Select Language(s)" isMulti/>
            <button onClick={handleSubmit}>Submit</button>
        </React.Fragment>
    )
}


export default Profile;