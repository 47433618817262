import React, {useState, useEffect} from "react";
import axios from "axios";

const URL_EDIT = process.env.REACT_APP_API_URL+"/grid/edit";



const Test = ()  => {
   // const example =`<p>L'utente predefinito è <strong>admin </strong>e la password è <strong>admin</strong>.<br><br><span class="wysiwyg-font-size-large"><strong>Se non avete accesso, dovremo generare una chiave temporanea, per recuperare la password dimenticata </strong></span></p> <p><span class="wysiwyg-font-size-large"><strong>Per questo abbiamo bisogno che ci venga inviato l'indirizzo MAC del dispositivo.</strong></span></p> <p> </p> <p>Nelle telecamere è necessario utilizzare DeviceConfigTool (nei download dei prodotti troverete l'ultima versione) per visualizzare il MAC di cui lo strumento ha bisogno:<br><br><img src="/attachments/token/movTpYH9vySTWUkMJ6p6qRS09/?name=inline712829762.png" data-original-height="181" data-original-width="907"><br><br>DeviceConfigTool:<br><a href="https://athena-visiotech.s3-eu-west-1.amazonaws.com/7l9nGW8B3rdHzDKKRPq6/Device%20Config%20Tool_1.0.0.33_2017_05_12_282.exe" rel="noreferrer">https://athena-visiotech.s3-eu-west-1.amazonaws.com/7l9nGW8B3rdHzDKKRPq6/Device%20Config%20Tool_1.0.0.33_2017_05_12_282.exe</a></p> <p> </p> <p><br><br>In questo modo è possibile vedere il MAC nel registratore:<br><br><img src="/attachments/token/VakjUiX6Phv3PPV2zFDw8bq0S/?name=inline1380606596.png" data-original-height="580" data-original-width="1184"></p> <p>Questo processo è fatto allo stesso modo per tutti questi modelli<br><br><br>- NV-KIT41-4CAM-5M-<br> NV-KIT41-4CAM-8M-<br> NV-KIT81-4CAM-8M-<br> NV-KIT61-4C2M-BAT-<br> NV-IPDM940HA-5-<br> NV-IPCV020H-5-<br> NV-IPCV020H-8</br></br></br></br></br></br></p>`;
    const [translation, setTranslation] = useState({});    
    const id = "5f1175b9ddb341033d15042c";
    const langReq = "it";

    useEffect(() =>{
        console.log("Start request");
        const url = URL_EDIT + '/' + langReq + '/' + id;     
        axios.get(url)
            .then(response => {
                
                setTranslation(response.data.translation);
                console.log("TranslatioN",translation);
                })            
            .catch(error => console.log("Error in GET ",error));    
    }, [translation]);
    return (
        <React.Fragment>
        <div>Hello</div>
        </React.Fragment>
    );
}

export default Test;