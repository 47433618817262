import React from "react";
import '../../styles/translationInfo.css';
import {FiUserCheck, AiOutlineClockCircle} from "react-icons/all";

export const TranslationInfo =  (props) => {
    console.log("PROPS ",props);
    return (
            <div className="detailMenu">
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">Status</div>
                    {props.status === "review" ?
                        <div className="descDetailMenu statusReview"><AiOutlineClockCircle/> {props.status}</div>
                        :
                        <div className="descDetailMenu statusReady"><FiUserCheck/> {props.status}</div>
                    }
                </div>
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">Project</div>
                    <div className="descDetailMenu">{props.project}</div>
                </div>
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">Document</div>
                    <div className="descDetailMenu">{props.document}</div>
                </div>
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">Modified</div>
                    <div className="descDetailMenu">{props.updatedAt}</div>
                </div>
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">ID</div>
                    <div className="descDetailMenu">{props._id}</div>
                </div>
                <div className="containerDetailMenu">
                    <div className="titleDetailMenu">IsArticle</div>
                    <div className="descDetailMenu">{props.isArticle}</div>
                </div>
            </div>
    );
}