import React from "react";
import "../../styles/detail.css";
import './DivArea.css';

export const TranslationArea = (props) =>{
    const onChange = (editor) => {
        //console.log("OnChangeEditor ",editor.currentTarget.textContent);
        props.updateTranslation(editor.currentTarget.textContent);
    }
    return(
        <div>
            <h2 className="title_box">{props.title}</h2>
            <div className="scrollbars" >
                <div 
                    onInput={onChange} 
                    onBlur={onChange}
                    contentEditable={true} 
                    style={{height:"100%"}}
                    suppressContentEditableWarning={true}
                    > 
                    
                    {props.content}
                </div>
            </div>
        </div>
    );
};