import React, { useState,useRef }  from 'react';
import {BiSearch,BiX} from 'react-icons/bi';
import './search-box.css';


const SearchBox = (props) => {
  const [state,setState]=useState("hide");
  const [defaultText,setDefaultText]=useState("");
  const searchInput = useRef(null)

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function toogleSearch(){
      if(state==="active"){
        setDefaultText("");
        setState("hide");
        props.onChange("");
      }else{
        setState("active");
        await sleep(200);
        searchInput.current.focus();
      }

    }

    function updateSearch(event){
      props.onChange(event.target.value);
      setDefaultText(event.target.value);
    }

      return (

        <div className={`search-box-wrap search-${state}`}  >
          <input ref={searchInput} type="text" value={defaultText} onChange={updateSearch} className={`input-${state}`}  placeholder="search by any field" />
          <BiSearch className="search-icon" onClick={toogleSearch}/>
          <BiX  className="clear-icon" onClick={toogleSearch}/>
        </div>
      );
  }
  
  export default SearchBox;