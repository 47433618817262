import React from 'react';
import Select from 'react-select';
import {customSelectStyles,  mainDivStyle} from './SelectorFilterStyles';

const SelectorFilter = React.forwardRef((props, ref) => {
    const {options, gridApi, placeholder} = props;
  
    let selectOptions = [];
    if(options){
        options.sort();
        for(let option of options){
            selectOptions.push({value: option, label: option});
        }
    }    

    const onChange = (selected) => {
        if(selected){
            ref.current = selected.value;            
        }
        else{
            ref.current = "";
        }
        gridApi.onFilterChanged();
        
    }
    
    return(
        <React.Fragment>
            <div style={mainDivStyle}>
                <label className="titleFilter">{placeholder}</label>
                <Select styles={customSelectStyles} options={selectOptions} onChange={onChange} placeholder={placeholder} isClearable={true}/>
            </div>
        </React.Fragment>
    );
});

export default SelectorFilter;