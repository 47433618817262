import React from "react";

import { useForm } from "../../../hooks/FormContext";
import TranslationLang from "../TranslationLang/TranslationLang";

function TranslationArea() {    
    const {getTranslationContent} = useForm();
    const translationContent = getTranslationContent();

    return (    
        <>    
            {translationContent?  
                (Object.keys(translationContent).map((lang, index) => {
                    return (
                        <TranslationLang text={translationContent[lang]} lang={lang} />
                        );
                })
            ): null}
            
        </>
    )
}

export default TranslationArea;