import React, { useEffect } from "react";
import { useForm } from "../../hooks/FormContext";

import LanguageSwitch from './LanguageSwitch';

const LanguageSelector = () => {

    const { getLangs, getFormData, updateFormData } = useForm();
    const formData = getFormData();
    const langs = getLangs();


    /**
     * Define the amount of checkboxes based on languages
     */
    useEffect(() => {
        let checkValuesObject = {}
        for (let lang in langs) {
            checkValuesObject[lang] = true;
        }        
        updateFormData({...checkValuesObject });
    }, [])



    const handlerCheckClick = (lang) => {
        const formData = getFormData();
        updateFormData({ [lang]: !formData[lang]});
    }

    return (
        <>
            {
                Object.entries(langs).map(([lang, langText]) => {
                    return <LanguageSwitch
                        checked={formData[lang]} id={lang} key={lang} text={langText} onClick={() => handlerCheckClick(lang)}
                    />
                })
            }
        </>

    );
}



export default LanguageSelector;
