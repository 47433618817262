import React,  {createContext, useState, useMemo, useContext, useEffect} from 'react';
import jwt from 'jwt-decode';


import AxiosHeaders from '../services/axios.headers';

const UserContext = createContext();


export function  UserProvider ({children}) {

    const [isAuth, setIsAuth]  = useState(() =>{
        let token = window.localStorage.getItem('token');
        if(token && token !== "undefined"){   
            try {
                let data = jwt(token);
                if (data.user){
                    return true;
                }
            } catch (error) {
                console.log("Error getting auth ", error);
                return false;
            }
        }
    });

    const isRole = (role) =>{
        let token = window.localStorage.getItem('token');
        if(token && token !== "undefined"){   
            try {
                let data = jwt(token);
                if (typeof data.user.role !=="undefined"){
                    return data.user.role === role ? true
                            : false;
                }
            } catch (error) {
                console.log("Error getting "+role+" role ", error);
                return false
            }
        }
    }

    const [isTranslator, setIsTranslator]  = useState(() =>{
        return isRole("TRANSLATOR");
    });

    const [isProducer, setIsProducer]  = useState(() =>{
        return isRole("PRODUCER");
    });

    const [isAdmin, setIsAdmin]  = useState(() =>{
        return isRole("ADMIN");
    });

   

    useEffect(() =>{
        AxiosHeaders();
    }, [isAuth, isTranslator, isProducer,isAdmin]);

   
    function checkRole(){
        try {
            let token = window.localStorage.getItem('token');
            console.log("Token ",JSON.stringify(token));
            if(token){
                try {
                    let data = jwt(token);
                    console.log("Data "+ JSON.stringify(data));
                    if(typeof data.user.role !=="undefined"){
                        let role = data.user.role;
                        console.log("Role in check Role " +role );
                       
                        switch(role){
                            case "PRODUCER":
                                setIsProducer(true);
                                setIsTranslator(false);
                                setIsAdmin(false);
                                console.log("Setting Producer");
                                break;
                            case "TRANSLATOR":
                                setIsTranslator(true);
                                setIsProducer(false);
                                setIsAdmin(false);
                                console.log("Setting translator ");
                                break;
                            case "ADMIN":
                                setIsAdmin(true);
                                setIsTranslator(false);
                                setIsProducer(false);
                                console.log("Setting translator ");
                                break;    
                            default:
                                console.log("No knwon role");
                                setIsTranslator(false);
                                setIsProducer(false);
                                setIsAdmin(false);

                        }
                    }
                    else
                    {
                        console.log("No rolee " +JSON.stringify(data))
                    }
                } catch (error) {
                    console.log("Error JWT token " + error);
                }
               
                return true;
            }
            else{
                console.log("No role")
                return false;
            }
        } catch (error) {
            console.log("Error checking token ",error);
            return false;
        }            
    }

    const logIn = (token) =>{
        setIsAuth(true);
        window.localStorage.setItem('token', token);
    }

    const logOut = () =>{
        setIsAuth(false);
        window.localStorage.removeItem('token');
    }

    //return true if there is a token and whether the user has a role or not
    const hasRole = () => {
        if(isProducer || isTranslator) return true;
        else return false;
    }

    const getLanguages = () => {
        try {
            let token = window.localStorage.getItem('token');
            if(token !== "undefined"){
                let data = jwt(token);
                return typeof data.user.languages !=="undefined" ? data.user.languages : false
            }
            else{
                return false;
            }
        } catch (error) {
            console.log("Error getting languages " + error);
            return false;
        }            
    }

    const getRole = () => {
        try {
            let token = window.localStorage.getItem('token');
            if(token !== "undefined"){
                let data = jwt(token);
                return typeof data.user.role !=="undefined" ? data.user.role : false
            }
            else{
                return false;
            }
        } catch (error) {
            console.log("Error getting role ",error);
            return false;
        }
    }
    

    const value = useMemo(() => {
        return({
            isAuth,
            isTranslator,
            isProducer,
            logIn,
            logOut,
            hasRole,
            getLanguages,
            getRole,
            checkRole
        })
    }, [isAuth, isTranslator, isProducer]);

    return(
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser(){
    const context = useContext(UserContext);
    if(!context){
        throw new Error("useUser must be inside UserContext Provider");
    }

    return context;
}