import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';

import { runTabifier } from "../assets/static/js/tabifier";

const FormContext = createContext();

export const FormProvider = ({ children }) => {

    let initialState = {
        is_repeatable: 1,
        isArticle: 0,
        sourceLang: "es",
        originalText: "",       

    };
    useEffect(() => {
        const initialLang = {};
        for (const lang in langs) {
            initialLang[lang] = true;
        }
        initialState = { ...initialState, ...initialLang };
    })

    const [formData, setFormData] = useState(initialState);
    const [translationContent, setTranslationContent] = useState({});
    const [langs, setLangs] = useState({
        "fr": "Francés",
        "pt": "Portugués",
        "it": "Italiano",
        "en": "Inglés",
        "de": "Alemán"
    });

    const updateFormData = (newState) => {
        setFormData((prevState) => {
            return {...prevState, ...newState}
        })
    }

    const resetForm = () => {
        setFormData(initialState);
        setTranslationContent({});
    }

    const getFormData = (prepareText = false) => {
        const originalText = formData["originalText"];
        if (prepareText && originalText) {
            debugger;
            const prettifiedOriginal = runTabifier(formData["originalText"]);
            const formatedData = { ...formData, originalText: prettifiedOriginal };
            setFormData(formatedData);
            return formatedData;
        }
        else {
            return formData;
        }
    }

    const checkLanguages = () => {
        let objectTrue = {}
        for (let lang in langs) {
            objectTrue[lang] = true;
        }
        setFormData({ ...formData, ...objectTrue });
    }

    const unCheckLanguages = () => {
        let objectFalse = {}
        for (let lang in langs) {
            objectFalse[lang] = false;
        }
        setFormData({ ...formData, ...objectFalse });
    }

    const value = useMemo(() => {
        return ({
            getFormData,
            getLangs: () => langs,
            getTranslationContent: () => translationContent,
            updateFormData,
            updateLangs: (langs) => setLangs(langs),
            updateTranslationContent: (translationContent) => setTranslationContent(translationContent),
            checkLanguages,
            unCheckLanguages,
            resetForm
        });
    }, [formData, langs, translationContent]);

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
}

export function useForm() {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useForm must be inside FormProvider ");
    }

    return context;
}
