import React, { useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../../styles/detail.css";



import './DivArea.css';

export const TranslationAreaArticle = (props) =>{ 
    let reactQuillRef = useRef();
    var icons = ReactQuill.Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

   const myUndo = () => {
       
        let myEditor = reactQuillRef.current.getEditor();
        return myEditor.history.undo();
    }

    const myRedo = () => {
        let myEditor = reactQuillRef.current.getEditor();
        return myEditor.history.redo();
    }
    const  modules = {
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: false
          },
        toolbar: {
            container:[
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline','strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['undo'],
                ['redo']
            ],
            handlers: {
            'undo' : myUndo,
            'redo' : myRedo,
            }
        }       
      };
    
    const onChange = (editor) => {
        props.updateTranslation(editor);
    }
    return(
        <div>
            <h2 className="title_box">{props.title}</h2>
            <div className="scrollbars">
                <ReactQuill theme="snow" 
                            value={props.content}
                            onChange={onChange}
                            modules={modules}
                            icons={icons}
                            ref={(el) => {reactQuillRef.current = el}}/>      
            </div>
        </div>
    );
};