import React from "react";
import Form from "react-bootstrap/Form";

function LanguageSwitch({id, text, checked, onClick}) {
    const idText = `${id}Check`;
    return (        
        <Form.Check
        inline
        type="switch"
        label={text}
        name={idText}
        id={idText}
        checked={checked}
        onChange={onClick}
      />
    );
}

export default LanguageSwitch;